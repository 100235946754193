<template>
  <!--begin::Menu 2-->
  <div
    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px"
    data-kt-menu="true"
  >
    <!--begin::Menu item-->
    <div class="menu-item px-3">
      <div class="menu-content fs-6 text-dark fw-bolder px-3 py-4">
        Quick Actions
      </div>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu separator-->
    <div class="separator mb-3 opacity-75"></div>
    <!--end::Menu separator-->

    <!--begin::Menu item-->
    <div class="menu-item px-3" v-for="action in actions" :key="action">
      <a
        :href="action.url"
        @click.prevent="doAction(action)"
        class="menu-link px-3"
      >
        {{ action.name }}
      </a>
      <input
        v-if="action.uploadFile"
        :id="action.name + '_input'"
        type="file"
        style="display: none"
        @change="(evt) => onFileUpload(evt, action)"
      />
    </div>
    <!--end::Menu item-->

    <!--begin::Menu item-->
    <!--
    <div
      class="menu-item px-3"
      data-kt-menu-trigger="hover"
      data-kt-menu-placement="right-start"
      data-kt-menu-flip="left-start, top"
    >
      <a href="#" class="menu-link px-3">
        <span class="menu-title">New Group</span>
        <span class="menu-arrow"></span>
      </a>

      <div class="menu-sub menu-sub-dropdown w-175px py-4">

        <div class="menu-item px-3">
          <a href="#" class="menu-link px-3"> Admin Group </a>
        </div>

        <div class="menu-item px-3">
          <a href="#" class="menu-link px-3"> Staff Group </a>
        </div>

        <div class="menu-item px-3">
          <a href="#" class="menu-link px-3"> Member Group </a>
        </div>

      </div>
    </div>
    -->
    <!--end::Menu item-->

    <!--begin::Menu separator-->
    <div class="separator mt-3 opacity-75"></div>
    <!--end::Menu separator-->

    <!--begin::Menu item-->
    <!--<div class="menu-item px-3">
      <div class="menu-content px-3 py-3">
        <a class="btn btn-primary btn-sm px-4" href="#"> Generate Reports </a>
      </div>
    </div>-->
    <!--end::Menu item-->
  </div>
  <!--end::Menu 2-->

  <div
      class="modal fade"
      id="kt_modal_action_result"
      tabindex="-1"
      aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header" id="kt_modal_create_api_key_header">
          <!--begin::Modal title-->
          <h2>Information</h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>

        <!--begin::Modal body-->
        <div class="modal-body">
          <div class="fs-5" style="white-space: break-spaces">
            {{ this.response }}
          </div>
          <!--end::Modal body-->

          <!--begin::Modal footer-->
          <div class="modal-footer">
            <!--begin::Button-->
            <button
                ref="yesButtonRef"
                type="submit"
                id="kt_modal_yes"
                class="btn btn-primary"
                data-bs-dismiss="modal"
            >
              <span class="indicator-label"> Ok </span>
            </button>
            <!--end::Button-->
          </div>
          <!--end::Modal footer-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import { toast } from "vue3-toastify";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "dropdown-2",
  components: {},
  props: {
    actions: { type: Array },
  },
  data: function () {
    return {
      response: "",
    }
  },

  methods: {
    onFileUpload: function (event, action) {
      const fileInput = document.getElementById(action.name + "_input");
      const file = fileInput.files[0];

      if (!file) {
        return;
      }

      let formData = new FormData();
      formData.append("file", file, file.name);

      ApiService.post(action.url, formData)
        .then((response) => {
          if (action.showResponse) {
            this.response = response.data;
            const modal = new Modal(document.getElementById("kt_modal_action_result"));
            modal.show();
          } else {
            toast.success("Success action");
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(
              "Error " +
                error.response.data.code +
                "\n" +
                error.response.data.error
            );
          } else {
            toast.error("Unknown error. Check console logs.");
          }
        });
    },

    doAction(action) {
      if (action.routerUrl) {
        this.$router.push({
          name: action.routerUrl,
        });
        return;
      }

      if (action.type && action.type === "external") {
        if (action.uploadFile) {
          const fileInput = document.getElementById(action.name + "_input");
          fileInput.click();
          return;
        }

        ApiService.post(action.url, {})
          .then((response) => {
            if (action.showResponse) {
              this.response = response.data;
              const modal = new Modal(document.getElementById("kt_modal_action_result"));
              modal.show();
            } else {
              toast.success("Success action");
            }
          })
          .catch((error) => {
            if (error.response) {
              toast.error(
                "Error " +
                  error.response.data.code +
                  "\n" +
                  error.response.data.error
              );
            } else {
              toast.error("Unknown error. Check console logs.");
            }
          });
      }
    },
  },
});
</script>
