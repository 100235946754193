<template>
  <div class="d-flex flex-column flex-lg-row">
    <div
      class="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-300px mb-10 mb-lg-0"
      v-if="filters && filters.length > 0"
    >
      <div class="card mb-3">
        <div class="card-body">
          <template v-for="filter in filters" :key="filter">
            <template v-if="filter.type === 'select'">
              <label class="mt-3">{{ filter.name }}</label>
              <div>
                <!--
                <v-select
                  class="vue-select"
                  :options="filter.options"
                  label="title"
                  :placeholder="filter.name"
                  :reduce="(option) => option.value"
                  v-model="filterValues[filter.value]"
                >
                </v-select>-->
                <el-select
                  size="small"
                  :placeholder="filter.name"
                  v-model="filterValues[filter.value]"
                  clearable
                  class="w-100"
                >
                  <el-option
                    v-for="option in filter.options"
                    :key="'option_' + filter.name + option.value"
                    :value="option.value"
                    :label="option.title"
                    >{{ option.title }}</el-option
                  >
                </el-select>
              </div>
            </template>
            <template v-else-if="filter.type === 'suggestion'">
              <label class="mt-3">{{ filter.name }}</label>
              <v-select
                label="title"
                :placeholder="filter.name"
                :reduce="(option) => option.id"
                v-model="filterValues[filter.value]"
                @search="
                  (search, loading) => fetchOptions(search, loading, filter)
                "
                :options="suggestionOptions[filter.value]"
              ></v-select>
            </template>
            <template v-else>
              <label class="mt-3">{{ filter.name }}</label>
              <input
                type="text"
                class="form-control"
                style="padding: 0.5rem 1rem"
                :placeholder="filter.name"
                v-model="filterValues[filter.value]"
                v-on:keyup.enter="setPage(0)"
              />
            </template>
          </template>
          <button class="btn btn-secondary w-100 mt-4" @click="clearSearch">
            Clear
          </button>
          <button class="btn btn-primary w-100 mt-4" @click="setPage(0)">
            Search
          </button>
        </div>
      </div>
    </div>

    <div class="flex-lg-row-fluid ms-lg-7 ms-xl-10">
      <!--begin::Tables Widget 13-->
      <div class="card">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1">{{ title }}</span>

            <span class="text-muted mt-1 fw-bold fs-7"
              >Всего: {{ dto.total }}</span
            >
          </h3>
          <div class="card-toolbar">
            <!--begin::Menu-->
            <button
              type="button"
              class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
              data-kt-menu-flip="top-end"
            >
              <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/general/gen024.svg" />
              </span>
            </button>
            <Dropdown2 :actions="totalActions"></Dropdown2>
            <!--end::Menu-->
          </div>
        </div>
        <!--end::Header-->

        <!--begin::Body-->
        <div class="card-body py-3">
          <!--begin::Table container-->
          <div class="table-responsive">
            <!--begin::Table-->
            <table
              class="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3"
            >
              <!--begin::Table head-->
              <thead>
                <tr class="fw-bolder text-muted">
                  <th class="w-25px">
                    <div
                      class="form-check form-check-sm form-check-custom form-check-solid"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        @change="
                          checkedRows =
                            checkedRows.length === 6 ? [] : [0, 1, 2, 3, 4, 5]
                        "
                      />
                    </div>
                  </th>
                  <th v-for="column in columns" :key="column">
                    {{ column.name }}
                  </th>
                  <th class="min-w-100px text-end">Actions</th>
                </tr>
              </thead>
              <!--end::Table head-->

              <!--begin::Table body-->
              <tbody>
                <template v-for="(item, index) in dto.data" :key="index">
                  <tr>
                    <td>
                      <div
                        class="form-check form-check-sm form-check-custom form-check-solid"
                      >
                        <input
                          class="form-check-input widget-13-check"
                          type="checkbox"
                          :value="index"
                          v-model="checkedRows"
                        />
                      </div>
                    </td>

                    <td v-for="col in columns" :key="col">
                      <a
                        :href="editPage + item.id"
                        @click.prevent="
                          this.$router.push({
                            name: editPageRouter,
                            params: { id: item.id },
                          })
                        "
                        :class="{
                          'text-danger': rowColorFunction
                            ? rowColorFunction(item)
                            : false,
                          'text-dark':
                            !rowColorFunction || !rowColorFunction(item)
                              ? true
                              : false,
                        }"
                        class="fw-bolder text-hover-primary fs-6"
                        >{{
                          col.processor
                            ? col.processor(item[col.value])
                            : item[col.value]
                        }}</a
                      >
                    </td>

                    <td class="text-end">
                      <a
                        :href="editPage + item.id"
                        @click.prevent="
                          this.$router.push({
                            name: editPageRouter,
                            params: { id: item.id },
                          })
                        "
                        class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                      >
                        <span class="svg-icon svg-icon-3">
                          <inline-svg
                            src="/media/icons/duotune/art/art005.svg"
                          />
                        </span>
                      </a>

                      <a
                        href="javascript:;"
                        @click="setDeleteItem(item)"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_delete"
                        class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                      >
                        <span class="svg-icon svg-icon-3">
                          <inline-svg
                            src="/media/icons/duotune/general/gen027.svg"
                          />
                        </span>
                      </a>
                    </td>
                  </tr>
                </template>
              </tbody>
              <!--end::Table body-->
            </table>
            <!--end::Table-->
          </div>
          <!--end::Table container-->
        </div>
        <!--begin::Body-->

        <ul class="pagination mb-12" v-if="dto.data.length > 0">
          <li
            :class="
              currentPage === 0
                ? 'page-item previous disabled'
                : 'page-item previous'
            "
          >
            <a
              href="javascript:;"
              v-on:click="setPage(currentPage - 1)"
              class="page-link"
              ><i class="previous"></i
            ></a>
          </li>
          <li
            v-for="p in totalPages"
            :class="currentPage == p - 1 ? 'page-item active' : 'page-item'"
            :key="p"
          >
            <a
              href="javascript:;"
              v-on:click="setPage(p - 1)"
              class="page-link"
              >{{ p }}</a
            >
          </li>
          <li
            :class="
              currentPage + 1 === totalPages
                ? 'page-item next disabled'
                : 'page-item next'
            "
          >
            <a
              href="javascript:"
              v-on:click="setPage(currentPage + 1)"
              class="page-link"
              ><i class="next"></i
            ></a>
          </li>
        </ul>
      </div>
      <!--end::Tables Widget 13-->
    </div>
  </div>

  <div
    class="modal fade"
    id="kt_modal_delete"
    ref="its_me_modal_ref"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header" id="kt_modal_create_api_key_header">
          <!--begin::Modal title-->
          <h2>Удаление</h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>

        <!--begin::Modal body-->
        <div class="modal-body">
          <div class="fs-5">
            Вы уверены что хотите удалить id {{ item.id }}?
          </div>
          <!--end::Modal body-->

          <!--begin::Modal footer-->
          <div class="modal-footer">
            <!--begin::Button-->
            <button
              ref="yesButtonRef"
              type="submit"
              id="kt_modal_yes"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click="deleteItem(item)"
            >
              <span class="indicator-label"> Да </span>
            </button>

            <button
              ref="cancelButtonRef"
              type="submit"
              id="kt_modal_cancel"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              <span class="indicator-label"> Отмена </span>
            </button>
            <!--end::Button-->
          </div>
          <!--end::Modal footer-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, nextTick } from "vue";
import Dropdown2 from "@/components/dropdown/Dropdown2";
import ApiService from "@/core/services/ApiService";
import vSelect from "vue-select";
import { toast } from "vue3-toastify";

export default defineComponent({
  name: "EntityTable",
  components: {
    Dropdown2,
    vSelect,
  },
  props: {
    columns: { type: Array },
    apiUrl: { type: String },
    title: { type: String },
    editPage: { type: String },
    deleteUrl: { type: String },
    editPageRouter: { type: String },
    filters: { type: Array },
    actionsUrl: { type: String },
    rowColorFunction: { type: Function },
  },

  data: function () {
    return {
      dto: {
        total: 0,
        data: [],
      },
      item: {
        id: 0,
      },
      checkedRows: [],
      list: [],
      totalActions: [],
      pageSize: 12,
      currentPage: 0,
      totalPages: 1,
      filterValues: {},
      suggestionOptions: {},
    };
  },

  created() {
    if (this.$route.query.page) {
      this.currentPage = this.$route.query.page - 1;
    }

    if (this.filters) {
      for (let i = 0; i < this.filters.length; i++) {
        let filter = this.filters[i];

        if (filter.value in this.$route.query) {
          let val = this.$route.query[filter.value];
          if (filter.type === "select") {
            val = parseInt(val);
          }
          if (filter.type === "suggestion") {
            ApiService.get("api", filter.url + `?id=` + val).then(
              (response) => {
                this.suggestionOptions[filter.value] = response.data;
                this.filterValues[filter.value] = response.data[0];
              }
            );
          }
          this.filterValues[filter.value] = val;
        }
      }
    }

    if (this.editPage) {
      this.totalActions.push({
        name: "New Item",
        url: this.editPage,
        routerUrl: this.editPageRouter,
      });
    }

    if (this.actionsUrl) {
      ApiService.get("api", this.actionsUrl)
        .then((response) => {
          let list = response.data;
          for (let i = 0; i < list.length; i++) {
            let action = list[i];
            this.totalActions.push({
              name: action.name,
              url: action.url,
              warning: action.warning,
              type: "external",
              uploadFile: action.uploadFile,
              showResponse: action.showResponse,
            });
          }
        })
        .catch((error) => {
          toast.error(
            "Error " +
              error.response.data.code +
              "\n" +
              error.response.data.error
          );
        });
    }
    this.setPage(this.currentPage);
  },

  methods: {
    setDeleteItem: function (item) {
      this.item = item;
    },

    deleteItem: function (item) {
      ApiService.post(this.deleteUrl + item.id)
        .then(() => {
          toast.success("Deleted successfully");
          this.setPage(0);
        })
        .catch((error) => {
          if (error.response) {
            toast.error(
              "Error " +
                error.response.data.code +
                "\n" +
                error.response.data.error
            );
          }
        });
    },

    clearSearch: function () {
      this.filterValues = {};
      this.setPage(0);
    },

    setPage: function (page) {
      this.currentPage = page;

      document.documentElement.scrollTop = document.body.scrollTop = 0;

      let start = this.currentPage * this.pageSize;

      let filterString = "";
      let query = {
        page: this.currentPage + 1,
      };
      for (let value in this.filterValues) {
        if (this.filterValues[value] || this.filterValues[value] === 0) {
          filterString += value + "=" + this.filterValues[value] + "&";
          query[value] = this.filterValues[value];
        }
      }

      ApiService.get(
        "api",
        this.apiUrl + `?start=${start}&count=${this.pageSize}&` + filterString
      ).then((response) => {
        this.dto = response.data;
        this.totalPages = Math.ceil(response.data.total / this.pageSize);
      });

      this.$router.replace({
        query: query,
      });
    },

    /**
     * Triggered when the search text changes.
     *
     * @param search  {String}    Current search text
     * @param loading {Function}	Toggle loading class
     */
    fetchOptions(search, loading, filter) {
      if (filter.loadAll) return;

      loading(true);

      ApiService.get("api", filter.url + `?query=` + search)
        .then((response) => {
          this.suggestionOptions[filter.value] = response.data;
        })
        .finally(() => {
          loading(false);
        });
    },
  },
});
</script>
